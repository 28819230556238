import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { deleteTag } from 'utils/emabler';

export default function DeleteTagDialog({ open, setOpen, userId, tagId, setResponse }) {
  function handleCancel() {
    setOpen(false);
  }

  function handleDelete(e) {
    e.preventDefault();
    deleteTag(userId, tagId).then((r) => {
      setResponse(r);
    });
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="delete-user-tag-from-system"
      aria-describedby="delete-user-tag-description"
    >
      <DialogTitle>Delete Tag</DialogTitle>
      <DialogContent>
        <Typography variont="body1">Are you sure that you want to delete Tag: {tagId}</Typography>
        <DialogActions>
          <Button type="submit" onClick={handleDelete}>
            Delete Tag
          </Button>
          <Button autoFocus variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}


import { OutlinedInput,ListItemText, Checkbox, FormControl, InputLabel,Select,MenuItem,} from '@mui/material' ;

export default function MultiSelectSystemId({allowedSystems, selected, setSelected}){

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setSelected(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    return (
        <FormControl sx={{ m: 1}}>
            <InputLabel id="system-id-multiple-checkbox-label">System Id</InputLabel>
            <Select
              labelId="system-id-multiple-checkbox-label"
              id="system-id-multiple-checkbox"
              multiple
              value={selected}
              onChange={handleChange}
              input={<OutlinedInput label="System Ids" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {allowedSystems.map((system,i) => (
                <MenuItem key={i} value={system.groupName}>
                {<Checkbox checked={selected.indexOf(system.groupName) > -1} />}
                  <ListItemText primary={system.groupName} />
                </MenuItem>
              ))}
            </Select>
        </FormControl>
    )
}
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export default function FabAdd({ setOpen }) {
  function handleClick() {
    setOpen(true);
  }
  return (
    <Fab
      onClick={handleClick}
      color="primary"
      aria-label="add-user"
      sx={{ position: 'fixed', bottom: 100, right: 20 }}
    >
      <AddIcon />
    </Fab>
  );
}

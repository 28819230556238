export const palette= {
        primary: {
        main: '#1a2b55',
        dark: '#00002b',
        light: '#475281',
        contrastText: '#ffffff'
        },
        secondary: {
        main: '#ff8f15',
        dark: '#c55f00',
        light: '#FFBF4E',
        contrastText:'#000000'
        },
        text: {
            primary: '#1a2b55',
            secondary: '#2a457a',
            disabled: '#576e9c',
        },
        background:{
            //paper: #fff,
            //default: #fff,
            gradient: 'radialGradient(circle at 20% -20%, rgba(255,209,18,1) 0%, rgba(255,44,16,1) 100%)'
            //gradient: 'linear-gradient(to right, tomato, cyan)'
        },
        error: {
    main: '#f64234',
    light: '#f2534f',//#e77372
    dark: '#d62e2e',
    contrastText: '#fff',
},
warning:{
    main: '#ec9e00',
    light: '#edb600', 
    dark: '#eb7300', 
    contrastText: '#fff',
},
info: {
    main: '#1693bd',
    light: '#3DB8E1',
    dark: '#005e76',
    contrastText: '#fff',
},
success: {
    main: '#3fa654',
    light: '#5cb36d', 
    dark: '#247536', 
    contrastText: '#fff',
    
},
grey: {
    50:'#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#B0B0B0',//my
    500: '#979797',//my
    600:' #757575',
    700: '#646464', //my
    800: '#424242',
    900: '#212121',
    A100: '#f5f5f5',
    A200: '#eeeeee',
    A400: '#B0B0B0',
    A700: '#646464'},
    }

const generatedBlue={
    900:'#1a2b55',
    800:'#233b6d',
    700: '#2a457a',
    600:'#324e85',
    500:'#39568e',
    400:'#576e9c',
    300:'#7587ad',
    200:'#9ba9c5',
    100:'#c2cbdc',
    50:'#e7eaf0',
}
const generatedOrange={
    900:'#e95411',
    800:'#f26e12',
    700: '#f97e14',
    600:'#ff8e15',
    500:'#ff9a16',
    400:'#ffa92f',
    300:'#ffb852',
    200:'#ffcd83',
    100:'#ffe1b4',
    50:'#fff3e1',
}


import { Box,Link,Stack, Typography, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { routes } from '../App';


export default function PageNotFound404() {
  const theme = useTheme();
  const isSmall= useMediaQuery(theme.breakpoints.down('sm'));
  return (
    
    <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
      sx={{
        width: '100vw',
        height: '100vh'
    }}
  >
    <Stack 
      direction="column"  
      alignItems="center"
      spacing={10}
      sx={isSmall?{width:'80%'}:{width:'580px'}}
    >
      <Typography variant="h1" component="p" sx={{fontWeight: 800}}>
        404
      </Typography>
      <Link href={routes.home}>
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/logo/logo-287x287.png`} 
        alt="sun2wheel logo" 
        sx={{maxWidth:'120px'}}
      />
      </Link>
      <Typography variant="h4" component="p" sx={{fontWeight: 600}}>
        The requested page was not found. 
      </Typography>
  </Stack>
  </Stack>
)
}


import BaseLayout from "../layout/BaseLayout";
import UserManagement from "../components/UserManagement/UserManagement";

export default function UserManagementPage() {
    
    return (
            <BaseLayout child={<UserManagement />}/>
    )
}

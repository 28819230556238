import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
// returns Group ID!
export default function SelectSystemIdForForm({ allowedSystems, value, handleChange, error = '' }) {
  return (
    <FormControl sx={{ m: 0 }} error={error ? true : false}>
      <InputLabel id="select-system-id-label">SystemId</InputLabel>
      <Select
        variant="outlined"
        labelId="select-system-id-label"
        value={value ? value : ''}
        label="System Id"
        name="systemId"
        onChange={handleChange}
        sx={{ height: '39px', maxWidth: '300px' }}
      >
        {allowedSystems.map((v, ix) => {
          return (
            <MenuItem value={v.id} key={ix}>
              {v.groupName}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

import { useState } from 'react';
import { Typography, Stack, Card, CardContent, Button } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import RebootChargerDialog from './RebootChargerDialog';

export default function ChargerCard({ charger, setResponse }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Card sx={{ maxWidth: '300px' }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1" component="span">
              Charger Id: {charger.chargerId}
            </Typography>
            <Button startIcon={<BuildIcon />} onClick={() => setOpen(true)}></Button>
          </Stack>
        </CardContent>
      </Card>
      <RebootChargerDialog
        open={open}
        setOpen={setOpen}
        charger={charger}
        setResponse={setResponse}
      />
    </>
  );
}

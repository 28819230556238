import './index.css'

import { BrowserRouter ,Route, Routes,} from "react-router-dom";

import {AuthProvider} from "./hooks/useAuth";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {theme} from './theme/index';

import SystemStatusPage from "./routes/SystemStatus";
import LoadProfilePage from './routes/LoadProfile';
import { AuthPage } from './routes/AuthPage';
import { ProtectedRoute } from './routes/ProtectedRoute';
import HomePage from './routes/Home';
import AccountProfilePage from './routes/AccountProfile';
import TransactionDataPage from './routes/transactionData';

import UserManagementPage from './routes/UserManagement';
import ChargerManagementPage from './routes/ChargerManagement';
import UserManagementPage2 from './routes/UserManagement2';
import PageNotFound404 from './components/PageNotFound404';
import { AuthWithPassPage } from 'routes/AuthWithPassPage';

export const routes= {
  systemStatus: '/system_status',
  loadProfile: '/load_profile',
  transactionData:'/transaction_data',
  account:'/account',
  home:'/',
  signin:'/sign_in',
  paswordSignin:'/sign_in_with_pass',
  userMgmt:'/user_management',
  userMgmt2:'/user_management2',
  chargerMgmt:'/charger_management',
}

export default function App() {

const protectedRoutes = [
  {path:routes.systemStatus , 
  element: <SystemStatusPage />
  },
  {path:routes.loadProfile , 
  element: <LoadProfilePage />
  },
  {path:routes.transactionData , 
  element: <TransactionDataPage />
  },
  {path:routes.account , 
  element: <AccountProfilePage />
  },
  {path:routes.home, 
    element: <HomePage  />
  },
  {path:routes.userMgmt, 
    element: <UserManagementPage  />
  },
  {path:routes.userMgmt2, 
    element: <UserManagementPage2  />
  },
  {path:routes.chargerMgmt , 
    element: <ChargerManagementPage  />
  },
  
]

    return (
      <AuthProvider>
        <ThemeProvider theme={theme}>
        <CssBaseline />
            <BrowserRouter>
                <Routes>
                  {protectedRoutes.map((route,i)=>(
                    <Route
                    key={i}
                      path={route.path}
                      element={
                        <ProtectedRoute >
                          {route.element}
                        </ProtectedRoute>
                      }
                    />
                    )
                    )}
              <Route path={routes.signin} element={<AuthPage />} />
              <Route path={routes.paswordSignin} element={<AuthWithPassPage />} />
              <Route path="*" element={<PageNotFound404 />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>           
      </AuthProvider>
    )
}

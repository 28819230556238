import react, { useEffect, useState, useContext } from 'react';

import TxDateRangePickerKK from '../Reusable/DateRangePickerKK';
import { Typography, Box, Container, Stack, Tab, Tabs } from '@mui/material';
import { AuthContext } from 'hooks/useAuth';
import { useProfile } from 'hooks/useProfile';
import { fetchAllSystems, fetchAllChargers, fetchTxData } from 'utils/emabler';
import { filterAllowedSystems, sortArrayByObjectValue } from 'utils/reausableFunctions';
import { columnsChargerDetail, columnsUserDetail } from './tableColumns';
import { TableTotal } from './TableTotal';
import { TableDetails } from './TableDetails';

const truncateDateWithCurrentTime = (date) => {
  let tNow = new Date();
  return date > tNow ? tNow : date;
};

const fetchTransactionData = (chargersObj, allowedSystems, startDate, endDate, setter) => {
  const filteredChargers = filterAllowedSystems(chargersObj, allowedSystems);
  let chargers = filteredChargers.map((v) => v.chargerId);

  fetchTxData(chargers, startDate, truncateDateWithCurrentTime(endDate))
    .then((r) => {
      let txArray = r.results;
      let txArrayWithId = txArray.map((r, ix) => {
        r['id'] = ix;
        return r;
      });
      return txArrayWithId;
    })
    .then((r) => {
      const totalUser = calculateTotal(r, 'userId', 'consumptionKWH');
      const totalCharger = calculateTotal(r, 'chargerId', 'consumptionKWH');
      setter({
        data: r,
        totalCharger,
        totalUser,
      });
    });
};
// calculateTotal(array, aggregatedBy= "userId", aggregatedKey= "consummedKWH")
function calculateTotal(array, aggregatedBy, aggregatedKey) {
  // create array of objects const result=[[id:..., sum:...]]
  const result = {};
  array.map((item) => {
    let userName = {};
    let rfidTag = {};
    if (aggregatedBy === 'userId') {
      userName = { userName: item.userName };
      rfidTag = { rfidTag: item.rfidTag };
    }
    if (result?.[item[aggregatedBy]]) {
      result[item[aggregatedBy]] = {
        id: item[aggregatedBy],
        sum: Number((result[item[aggregatedBy]].sum + item[aggregatedKey]).toFixed(3)),
        ...userName,
        ...rfidTag,
      };
    } else {
      result[item[aggregatedBy]] = {
        id: item[aggregatedBy],
        sum: item[aggregatedKey],
        ...userName,
        ...rfidTag,
      };
    }
  });
  //setter(result)
  return result;
}

function createRows(tableData, total, sortedBy, setter) {
  const newArray = [...tableData];
  const sorted = sortArrayByObjectValue(newArray, sortedBy);
  let itemId = '';
  let classNameFilled = false;
  const sortedWithSum = sorted.map((item, i) => {
    if (!Object.values(item).includes(itemId)) {
      classNameFilled = !classNameFilled;
      itemId = item[sortedBy];
    }
    const sum = total[item[sortedBy]].sum;
    return { ...item, sum: sum, class: classNameFilled ? 'filled' : 'notFilled' };
  });
  //setter(sortedWithSum)
  return sortedWithSum;
}

export function TransactionDataTable() {
  let [systems, setSystems] = useState([]);
  const initialData = {
    data: [],
    totalCharger: {},
    totalUser: {},
  };
  const [tabsValue, setTabsValue] = useState(0);
  const [chargers, setChargers] = useState([]);

  const [tableData, setTableData] = useState(initialData);

  const [allowedSystems, setAllowedSystems] = useState([]);

  const initialTime = {
    startDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7),
    endDate: new Date(Date.now()),
    key: 'selection',
  };

  const [selectedDate, setSelectedDate] = useState(initialTime);
  const authContext = useContext(AuthContext);
  const { profile } = useProfile(authContext.session.user.id);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    fetchAllSystems(setSystems);
    fetchAllChargers(setChargers);
  }, []);

  useEffect(() => {
    if (profile) {
      setAllowedSystems(profile.SystemIds);
    }
  }, [profile]);

  useEffect(() => {
    if (chargers.length > 0 && allowedSystems) {
      const { startDate, endDate } = selectedDate;
      fetchTransactionData(chargers, allowedSystems, startDate, endDate, setTableData);
    }
  }, [chargers, selectedDate, allowedSystems]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  return (
    <Box>
      <Stack direction="column" justifyContent="space-between" spacing={3}>
        <Typography variant="h4" component="h1">
          Transaction Data
        </Typography>
        <TxDateRangePickerKK setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
        <Tabs value={tabsValue} onChange={handleChange} aria-label="Transaction summary">
          <Tab
            label={
              <Typography variant="h5" component="p">
                Detailed
              </Typography>
            }
          />
          <Tab
            label={
              <Typography variant="h5" component="p">
                Total
              </Typography>
            }
          />
        </Tabs>
        <TabPanel value={tabsValue} index={0}>
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={3}
            //sx={{mr:'20%'}}
          >
            {tableData.data.length > 0 && (
              <TableDetails
                rows={createRows(tableData.data, tableData.totalCharger, 'chargerId')}
                columns={columnsChargerDetail}
                selectedDate={selectedDate}
                toolbarText="Chargers Transaction Details"
              />
            )}
            {tableData.data.length > 0 && (
              <TableDetails
                rows={createRows(tableData.data, tableData.totalUser, 'userId')}
                columns={columnsUserDetail}
                selectedDate={selectedDate}
                toolbarText="Users Transaction Details"
              />
            )}
          </Stack>
        </TabPanel>
        <TabPanel value={tabsValue} index={1}>
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={3}
            //sx={{mr:'20%'}}
          >
            <TableTotal
              rows={tableData.totalCharger}
              columns={[
                { field: 'id', headerName: 'Charger ID', width: 120 },
                { field: 'sum', headerName: 'Total kWH', width: 120, type: 'number' },
              ]}
              selectedDate={selectedDate}
              toolbarText="Total Chargers Transactions"
            />
            <TableTotal
              rows={tableData.totalUser}
              columns={[
                { field: 'userName', headerName: 'User Name', width: 120 },
                { field: 'id', headerName: 'User Id', width: 120 },
                { field: 'rfidTag', headerName: 'rfidTag', width: 150 },
                { field: 'sum', headerName: 'Total kWH', width: 120, type: 'number' },
              ]}
              selectedDate={selectedDate}
              toolbarText="Total Users Transactions"
            />
          </Stack>
        </TabPanel>
      </Stack>
    </Box>
  );
}

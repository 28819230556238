import React, { useContext, useState, useEffect, createContext } from 'react';
import { supabase } from '../supabaseClient';
import {getProfile} from "../utils/apis";

// create a context for authentication
//const initialState = { session: null, user: null }


export const useProfile = (id) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    setLoading(true);
    getProfile(id)
    .then((response)=>{
      setProfile(response.data);
      setError(response.error);
      setLoading(false);
    })
  }, []);



  return {loading, error,profile}
};



import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

import { setMinutes } from 'date-fns';

export default function TxDateRangePickerKK({setSelectedDate, selectedDate}) {
function handleChange(e){
    const {startDate, endDate}=e.selection;
        setSelectedDate({
            startDate,
             endDate: setMinutes(endDate,(60*24)-1),
              key: "selection"
        })
}

    return (
        <DateRangePicker
            onChange={handleChange}
            showSelectionPreview={true}
            showDateDisplay={false}
            moveRangeOnFirstSelection={false}

            months={2}
            ranges={[selectedDate]}
            direction="horizontal"
            rangeColors={['orange']}
            maxDate={new Date(Date.now()+(1000*60*60*24))}
            dateDisplayFormat='dd MMMM y'
            monthDisplayFormat='MMMM'
            weekStartsOn={1}
            fixedHeight={true}
        />
        )
    }
import react, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../hooks/useAuth';
import { useProfile } from '../../hooks/useProfile';
import { fetchAllSystems, fetchUsersByGroupPromise } from '../../utils/emabler';
import { Typography, Stack, Fab, CircularProgress } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AddEditUserDialog from './AddEditUserDialog';
import UsersList from './UsersList';
import MultiSelectSystemId from '../Reusable/MultiSelectSystemId';
import FabAdd from '../Reusable/FabAdd';
import { filterAllowedSystems } from 'utils/reausableFunctions';

export default function UserManagement() {
  const [allowedSystems, setAllowedSystems] = useState([]);
  const [allSystems, setAllSystems] = useState([]);
  const [usersGroups, setUsersGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // just to rilter results by user
  const [selectedUsersGroups, setSelectedUsersGroups] = useState([]);
  const [selected, setSelected] = useState([]);

  const authContext = useContext(AuthContext);
  const { profile } = useProfile(authContext.session.user.id);

  useEffect(() => {
    fetchAllSystems(setAllSystems);
  }, []);

  //filter allowed systems
  useEffect(() => {
    if (profile) {
      const filteredSystems = filterAllowedSystems(allSystems, profile.SystemIds);
      setAllowedSystems(filteredSystems);

      const systemIdArray = filteredSystems.map((system) => system.groupName);
      setSelected(systemIdArray);
    }
  }, [profile, allSystems]);
  // fetch users- promise all
  useEffect(() => {
    if (allowedSystems.length > 0) {
      const getUsersArray = [];
      allowedSystems.map((system) => {
        getUsersArray.push(fetchUsersByGroupPromise(system.id));
      });
      setLoading(true);
      Promise.all(getUsersArray)
        .then((response) => {
          setLoading(false);
          return setUsersGroups(response);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [allowedSystems]);

  return (
    <>
      <Stack direction="column" justifyContent="space-between" spacing={3}>
        <Typography variant="h4" component="h1">
          User Management
        </Typography>
        {allowedSystems && (
          <MultiSelectSystemId
            allowedSystems={allowedSystems}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        {loading && <CircularProgress />}

        {filterAllowedSystems(usersGroups, selected).map((userGroup, i) => (
          <UsersList key={i} userGroup={userGroup} allowedSystems={allowedSystems} />
        ))}
        {/*(usersGroups.length>0) && usersGroups.map((userGroup,i )=>(<UsersList key={i} userGroup={userGroup}/>))*/}
      </Stack>
      <FabAdd setOpen={setOpen} />
      <AddEditUserDialog open={open} setOpen={setOpen} allowedSystems={allowedSystems} />
    </>
  );
}


import { Container } from '@mui/material';

export default function FullPageLayout({ child })  {

    return (
        <Container 
        disableGutters 
        sx={{
            width: '100vw',
            height: '100vh'
        }}
        >
                {child}
        </Container>
    )
}


import { useState } from 'react';
import {
  Stack,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
} from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddEditUserDialog from './AddEditUserDialog';
import AddTagDialog from './AddTagDialog';
import DeleteUserDialog from './DeleteUserDialog';
import DeleteTagDialog from './DeleteTagDialog';
import TagCard from './TagCard';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';

export default function UserCard({ user, allowedSystems, setResponse }) {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [openEditTag, setOpenEditTag] = useState(false);
  //console.log('user', user);
  return (
    <Grid item sx={{ mr: 2 }}>
      <Card sx={{ minHeight: '250px', width: '360px' }}>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
          sx={{ height: '100%', minHeight: '250px' }}
        >
          <CardContent sx={{ width: '100%' }}>
            <Typography variant="h6" component="p" sx={{ pb: 1, height: '70px' }}>
              User Name: {user.name}{' '}
            </Typography>

            {user.tags.map((tag, i) => (
              <TagCard key={i} tag={tag} userId={user.id} setResponse={setResponse} />
            ))}
          </CardContent>
          <CardActions
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              width: '100%',
            }}
          >
            <Button
              variant="text"
              onClick={() => setOpenDelete(true)}
              startIcon={<DeleteIcon />}
              sx={{ mb: 1, gridColumn: 'span 1', width: '20px' }}
            />
            <Button
              variant="text"
              onClick={() => setOpenTag(true)}
              startIcon={<ModeEditOutlineIcon />}
              sx={{ mb: 1, gridColumn: 'span 1' }}
            >
              Add Tag
            </Button>
            <Button
              variant="text"
              onClick={() => setOpen(true)}
              startIcon={<ModeEditOutlineIcon />}
              sx={{ mb: 1, gridColumn: 'span 1' }}
            >
              Edit User
            </Button>
          </CardActions>

          <DeleteUserDialog
            user={user}
            open={openDelete}
            setOpen={setOpenDelete}
            setResponse={setResponse}
          />
          <AddEditUserDialog
            user={user}
            open={open}
            setOpen={setOpen}
            allowedSystems={allowedSystems}
            setResponse={setResponse}
          />
          <AddTagDialog user={user} open={openTag} setOpen={setOpenTag} setResponse={setResponse} />
        </Stack>
      </Card>
    </Grid>
  );
}
/*
              <Box
                key={i}
                sx={{
                  display: 'grid',
                  gridTemplateRow: '1fr',
                  gridAutoFlow: 'row',
                  width: '100%',
                  mb: '10px',
                }}
              >
                <Typography variant="body1">Tag Id:{tag.idTag}</Typography>
                <Typography variant="body1">Expires: {tag.expiringDate}</Typography>
                <Typography variant="body1">Starts: {tag.startDate}</Typography>
                <Button
                  variant="text"
                  startIcon={<ClearIcon />}
                  sx={{ gridColumn: '2', gridRow: '1/4', justifySelf: 'end' }}
                  onClick={() => setOpenEditTag(true)}
                />
                <DeleteTagDialog
                  userId={user.id}
                  tagId={tag.idTag}
                  open={openEditTag}
                  setOpen={setOpenEditTag}
                  setResponse={setResponse}
                />
              </Box>
              */





import { DataGrid,} from '@mui/x-data-grid';
import { Box} from '@mui/material' ;
import { lighten } from '@mui/material/styles';
import {CustomTableToolbar} from "../Reusable/CustomTableToolbar";




export function TableDetails({rows,columns, selectedDate, toolbarText}){
    const getBackgroundColor = (color) =>
    lighten(color, 0.95);
  
  const getHoverBackgroundColor = (color) =>
   lighten(color, 0.9);
  
  const propsRows={

          '& .super-app-theme--filled': {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
            '&:hover': {
              bgcolor: (theme) =>
                getHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
            },
          },
  }


    return (
<Box
                    sx={propsRows}
                >
                    <DataGrid 
                        autoHeight
                        rows={rows}
                        columns={columns}
                        pageSize={25}
                        rowsPerPageOptions={[25, 50, 100]}
                        rowGroupingColumnMode="single"
                        density="compact"
                        components={{
                            Toolbar: CustomTableToolbar,
                        }}
                        componentsProps={{
                            toolbar: { 
                                text: toolbarText ,
                                date: {selectedDate}
                            }
                        }}
                        getRowClassName={(params) => `super-app-theme--${params.row.class}`}
                    />
                </Box>

        

    )
}


import AccountProfile from "../components/AccountProfile";
import BaseLayout from "../layout/BaseLayout";

export default function HomePage() {

    return (
            <BaseLayout child={<AccountProfile  />}/>
    )
}

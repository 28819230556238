import { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../hooks/useAuth';
import { useProfile } from '../../hooks/useProfile';
import { fetchAllSystems } from '../../utils/emabler';
import { Typography, Stack } from '@mui/material';
import ChargerCard from './ChargerCard';
import { filterAllowedSystems } from 'utils/reausableFunctions';
import SuccesErrorAlert from '../Reusable/SuccesErrorAlert';
import FabAdd from '../Reusable/FabAdd';
import AddEditChargerDialog from './AddEditChargerDialog';
const initialResponse = {
  data: null,
  succes: null,
  error: null,
};

export default function ChargersManagement() {
  const [allowedSystems, setAllowedSystems] = useState([]);
  const [allSystems, setAllSystems] = useState([]);
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(initialResponse);

  const authContext = useContext(AuthContext);
  const { profile } = useProfile(authContext.session.user.id);

  useEffect(() => {
    if (profile) {
      const filteredSystems = filterAllowedSystems(allSystems, profile.SystemIds);
      setAllowedSystems(filteredSystems);
    }
  }, [profile, allSystems]);

  useEffect(() => {
    fetchAllSystems(setAllSystems);
  }, []);

  useEffect(() => {
    if (response?.data) {
      fetchAllSystems(setAllSystems);
    }

    if (response?.error) {
      setTimeout(() => setResponse(initialResponse), 6000);
    }
    if (response?.succes) {
      setTimeout(() => setResponse(initialResponse), 4000);
    }
  }, [response]);

  return (
    <Stack direction="column" justifyContent="space-between" spacing={3}>
      <Typography variant="h4" component="h1">
        Chargers Management
      </Typography>
      <SuccesErrorAlert response={response} />
      {allowedSystems.length > 0 &&
        allowedSystems.map((system) => (
          <div key={system.id}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6">Group: {system.groupName}</Typography>
              {system.chargers.map((charger) => (
                <ChargerCard key={charger.id} charger={charger} setResponse={setResponse} />
              ))}
            </Stack>
          </div>
        ))}
      {/*<FabAdd setOpen={setOpen} />*/}
      <AddEditChargerDialog
        open={open}
        setOpen={setOpen}
        allowedSystems={allowedSystems}
        setResponse={setResponse}
      />
    </Stack>
  );
}

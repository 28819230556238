
import BaseLayout from "../layout/BaseLayout";
import UserManagement2 from "../components/UserManagement/UserManagement2";

export default function UserManagementPage2() {
    
    return (
            <BaseLayout child={<UserManagement2 />}/>
    )
}

import * as React from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {InfluxDB} from '@influxdata/influxdb-client'
import { useTheme } from '@mui/material/styles';

const token = 'gJ7eSwPp5t2Tyt1mxxOJNQe6t5-eOwvtP_98W1-73DWXkXIzAJwKn_jfsI6LUv3KWimuYevFl-2IN1TQiyDAgQ==';
const org = 'sandro.schopfer@sun2wheel.ch';
const client = new InfluxDB({url: 'https://eu-central-1-1.aws.cloud2.influxdata.com', token: token});
const queryApi = client.getQueryApi(org);

// regex
const ds_ = /(ds_)/;
const beginning= /^/;

export default function SystemIdSelectorKK({setSysIdSelected, sysIdSelected, allowedSystems}) {

    const [allSystems, setAllSystems]= React.useState([]);
    //const theme = useTheme();

    function filterBySystemIds(res, allowedSystemIds) {
        if(allowedSystemIds[0]==="*" ){
            return res;
        } else{

            // SystemId array has ds_ in the beginning of every entry. ds_ is replaced with '' before beeing compared with SystemIds from response
            return res.filter(r => allowedSystemIds.map((sysId)=>sysId.replace(ds_ ,'')).includes(r))
        } 
    }

 
    function QueryAllSystems() {
        
        const query = `import "influxdata/influxdb/v1"
                        v1.tagValues(
                        bucket: "DCMSMessages",
                        tag: "SystemId",
                    )`

                    queryApi
                .collectRows(query)
                .then(data => {
                    data.forEach(x => JSON.stringify(x))
                    console.log('\nCollect ROWS SUCCESS sys ids');
                    let _data = data.map(x => x._value);

                    const filtered= _data
                        .filter((item)=>item.length<23)
                        .filter((item=> item.length>16))
                        .map((item)=>item.replace( ds_, ''))
                        .reduce((acc,item)=> !acc.includes(item) ? [...acc,item]: acc,[])
                        .sort()

                    const filteredAllowed = filterBySystemIds(filtered, allowedSystems);
                    setAllSystems(filteredAllowed);
                    })
                .catch(error => {
                    console.error(error);
                    console.log('\nCollect ROWS ERROR');
                })
    }

    React.useEffect(()=> {
        QueryAllSystems()
    }, [])


    return (
        <FormControl sx={{ m: 0, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label" 
                sx={{
                    //color: theme.palette.primary.contrastText,
                    }}>
                SystemId
            </InputLabel>
            <Select
            variant="outlined"
            labelId="demo-simple-select-label"
            value={sysIdSelected? sysIdSelected: ''}
            label="SystemId"
            onChange={(e)=>setSysIdSelected(e.target.value)}
            sx={{height:'39px',
                    maxWidth:'225px'
                //backgroundColor: theme.palette.primary.main,
                //color: theme.palette.primary.contrastText
            }}
            >
                {
                    allSystems.map((v, ix)=> {
                        return <MenuItem value={v} key={ix}> {v} </MenuItem>
                    })
                }
            </Select>
        </FormControl>
    );
    }

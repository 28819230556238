import { routes } from 'App';
import { Navigate } from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';

export const ProtectedRoute = ({ children }) => {
  const {session, loading}= useAuth();
  //console.log('session',session);
  //console.log('loading',loading);
  if (loading){return <p>loading</p>}
  else{
    if (!session) {
      <p>loading...</p>
      //console.log("protected route is navigationg to /sign in")
      return <Navigate to={routes.signin} replace />;
    } else {
      return children;
    }
  }
};
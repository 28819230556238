import { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../hooks/useAuth';
import { useProfile } from '../../hooks/useProfile';
import { fetchAllSystems, fetchUsersByGroup } from '../../utils/emabler';
import { Typography, Stack, Fab } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AddEditUserDialog from './AddEditUserDialog';
import SelectSystemId from '../Reusable/SelectSystemId';
import UsersList from './UsersList';
import { filterAllowedSystems } from 'utils/reausableFunctions';
import SuccesErrorAlert from '../Reusable/SuccesErrorAlert';
import FabAdd from '../Reusable/FabAdd';
const initialResponse = {
  data: null,
  succes: null,
  error: null,
};

export default function UserManagement2() {
  const [allSystems, setAllSystems] = useState([]);
  const [allowedSystems, setAllowedSystems] = useState([]);

  const [usersGroup, setUsersGroup] = useState([]);
  const [sysIdSelected, setSysIdSelected] = useState('');
  const [open, setOpen] = useState(false);

  const [response, setResponse] = useState(initialResponse);

  const authContext = useContext(AuthContext);
  const { profile } = useProfile(authContext.session.user.id);

  useEffect(() => {
    fetchAllSystems(setAllSystems);
  }, []);

  useEffect(() => {
    if (profile) {
      const filteredSystems = filterAllowedSystems(allSystems, profile.SystemIds);
      setAllowedSystems(filteredSystems);
    }
  }, [profile, allSystems]);

  useEffect(() => {
    if (sysIdSelected !== '') {
      fetchUsersByGroup(sysIdSelected, setUsersGroup);
    }
  }, [sysIdSelected]);

  useEffect(() => {
    // TODO fetch only one user
    if (response?.data) {
      fetchUsersByGroup(sysIdSelected, setUsersGroup);
    }
    if (response?.error) {
      setTimeout(() => setResponse(initialResponse), 6000);
    }
    if (response?.succes) {
      setTimeout(() => setResponse(initialResponse), 4000);
    }
  }, [response]);

  return (
    <>
      <Stack direction="column" justifyContent="space-between" spacing={3}>
        <Typography variant="h4" component="h1">
          User Management
        </Typography>
        {allowedSystems && (
          <SelectSystemId
            allowedSystems={allowedSystems}
            sysIdSelected={sysIdSelected}
            setSysIdSelected={setSysIdSelected}
          />
        )}
        <SuccesErrorAlert response={response} />
        {usersGroup.length > 0 &&
          usersGroup.map((userGroup, i) => (
            <UsersList
              key={i}
              userGroup={userGroup}
              allowedSystems={allowedSystems}
              setResponse={setResponse}
            />
          ))}
      </Stack>
      <FabAdd setOpen={setOpen} />
      <AddEditUserDialog
        open={open}
        setOpen={setOpen}
        allowedSystems={allowedSystems}
        setResponse={setResponse}
      />
    </>
  );
}

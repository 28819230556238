import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { supabase } from '../supabaseClient';
import { Box, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';

const getSoC = (dcmsReq) => {
  let Emax = dcmsReq.batteryCapacity[0];
  if (Emax === 0.0 || Emax === undefined || Emax === null) {
    return '';
  } else {
    let soc = dcmsReq.batteryCapacity[1] / Emax;
    return soc.toFixed(2);
  }
};

let plugStateField = (plugId, hide) => {
  return {
    field: 'plugStates' + plugId,
    headerName: plugId + ' State',
    description: 'Current state of a ' + plugId,
    sortable: false,
    type: 'string',
    width: 150,
    hide: hide,
    valueGetter: (params) => {
      let s1 = '';
      let keyVal = 'plugStates';
      if (Object.keys(params.row).includes(keyVal)) {
        s1 = params.row.plugStates[plugId];
        if ((s1 !== null) | (s1 !== undefined)) {
          return s1;
        }
      } else {
        return s1;
      }
    },
  };
};

const columns = [
  //{ field: 'id', headerName: 'ID', width: 10, },
  { field: 'systemId', headerName: 'SystemId', width: 190 },
  {
    field: 'presentConsumption',
    headerName: 'Psys (kW)',
    description: 'Consumption of the entire system (kW)',
    sortable: false,
    type: 'number',
    width: 100,
    valueGetter: (params) =>
      (params.row.presentConsumption.reduce((acc, v) => acc + v) / 1000.0).toFixed(2),
  },
  {
    field: 'presentConsumptionByPhase',
    headerName: 'Psys (% by phase)',
    description: 'Consumption of the entire system by phase',
    sortable: false,
    type: 'string',
    width: 140,
    hide: true,
    valueGetter: (params) => {
      let totCons = params.row.presentConsumption.reduce((acc, v) => acc + v);
      if (totCons === 0.0) {
        return [0, 0, 0];
      } else {
        return params.row.presentConsumption.map((v) => (v / totCons).toFixed(2));
      }
    },
  },
  { field: 'consumerId', headerName: 'chargerId', width: 180 },
  {
    field: 'firmware',
    headerName: 'Firmware',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    //type: 'number',
    width: 100,
    hide: true,
    //valueGetter: (params) => params[0],//params.reduce((acc, v) => acc +v),
  },
  {
    field: 'chargerConsumption',
    headerName: 'Pcomponent (kW)',
    description: 'Consumption of a component (charger/battery)',
    sortable: false,
    type: 'string',
    width: 140,
    hide: false,
    valueGetter: (params) => params.row.chargerConsumption.reduce((acc, v) => acc + v) / 1000.0,
  },
  {
    field: 'chargerConsumptionByPhase',
    headerName: 'Pcomp (% by phase)',
    description: 'Consumption of the entire system by phase',
    sortable: false,
    type: 'string',
    width: 140,
    hide: true,
    valueGetter: (params) => {
      let totCons = params.row.chargerConsumption.reduce((acc, v) => acc + v);
      if (totCons === 0.0) {
        return [0, 0, 0];
      } else {
        return params.row.chargerConsumption.map((v) => (v / totCons).toFixed(2));
      }
    },
  },
  {
    field: 'chargerStateOfCharge',
    headerName: 'SoC (%)',
    description: 'combined state of charge',
    sortable: false,
    type: 'number',
    width: 80,
    hide: false,
  },
  plugStateField('Plug0', false),
  plugStateField('Plug1', false),
  plugStateField('Plug2', false),
  plugStateField('Plug3', true),
  plugStateField('Plug4', true),
  plugStateField('Plug5', true),
  plugStateField('Plug6', true),
];

async function getUserSystemIds(user) {
  const { data, error } = await supabase.from('profiles').select('SystemIds').eq('id', user.id);
  return data[0].SystemIds;
}

function filterBySystemIds(res, allowedSystemIds) {
  let sysIdsFetched = Object.keys(res);
  let sysIdsToRemove = sysIdsFetched.filter((v) => !allowedSystemIds.includes(v));

  sysIdsToRemove.forEach((sysId) => {
    // remove items from json
    delete res[sysId];
  });
  return res;
}

export function SystemStatusDataTable() {
  const S2WTK =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJzdW4yd2hlZWwiLCJhdXRob3JpemVkIjp0cnVlLCJjbGllbnQiOiJzYW5kcm8uc2Nob3BmZXJAZ21haWwuY29tIiwiZXhwIjoxOTc1MDQ3OTUzLCJpc3MiOiJzdW4yd2hlZWwiLCJyZXYiOjEsInN5c3RlbUlkIjoibm9uZSJ9.4-X0gTHAp6OjGJhz4iEC8nkScqU87NJSwPU6MJaDID4';

  //let lastReq = []
  //const tk = process.env.S2WAPIKEY
  let user = supabase.auth.user();
  //console.log("supabase user", user)
  let [allowedSystems, setAllowedSystems] = React.useState([]);

  React.useEffect(() => {
    getUserSystemIds(user).then((data) => setAllowedSystems(data));
  }, []);

  //let url = "http://localhost:8080/v1/listAll/lastReqs"
  //let url = "http://localhost:8080/v1/chargeModes/ds_00000000_30000.0AXX"
  let url = 'https://api.sun2wheel.com/v1/listAll/lastReqs';
  let [lastReqs, setLastReqs] = React.useState({});
  let [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    fetch(url, {
      method: 'GET',
      //mode: "no-cors",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${S2WTK}`,
      },
    })
      .then((r) => {
        return r;
      })
      .then((r) => r.json())
      .then((_r) => {
        getUserSystemIds(user)
          .then((allowedSystems) => {
            //console.log("allowedSys:= ", allowedSystems)
            if (allowedSystems[0] === '*' && allowedSystems.length === 1) {
              return _r;
            } else {
              let r = filterBySystemIds(_r, allowedSystems);
              return r;
            }
          })
          .then((r) => {
            setLastReqs(r);

            let data = Object.keys(r).reduce((acc, key) => {
              let newObj = {};
              newObj['id'] = r[key].consumerId;
              newObj['systemId'] = r[key].consumerId;
              newObj['presentConsumption'] = r[key].presentConsumption;
              newObj['firmware'] = r[key].firmware;

              let chargersIds = Object.keys(r[key].subConsumers);
              let sysStatus = chargersIds.map((consId) => {
                let chargerProps = Object.assign({}, newObj);

                chargerProps['id'] = r[key].consumerId + '|' + consId;
                chargerProps['consumerId'] = r[key].subConsumers[consId].consumerId;
                chargerProps['chargerConsumption'] = r[key].subConsumers[consId].presentConsumption;

                chargerProps['chargerStateOfCharge'] = getSoC(r[key].subConsumers[consId]);
                //console.log("chargerProps= ", chargerProps)
                let plugVals = r[key].subConsumers[consId].subConsumers;

                if (plugVals !== null) {
                  let plugIds = Object.keys(plugVals);
                  let plugStates = {};
                  plugIds.forEach((plugId) => {
                    //console.log("plugId ", plugId)
                    if (plugId !== null) {
                      plugStates[plugId] = plugVals[plugId].state;
                    }
                  });
                  chargerProps['plugStates'] = plugStates;
                }

                return chargerProps;
              });

              sysStatus.forEach((v) => acc.push(v));
              return acc;
            }, []);

            data = data.map((v, ix) => {
              v['id'] = ix;
              return v;
            });
            setTableData(data);
          });
      });
  }, []);

  return (
    <Box>
      <Stack direction="column" justifyContent="space-between" spacing={3} sx={{ height: 1500 }}>
        <Typography variant="h4" component="h1">
          System Status
        </Typography>
        <DataGrid
          rows={tableData}
          columns={columns}
          pageSize={30}
          rowsPerPageOptions={[120]}
          rowGroupingColumnMode="single"
          density="compact"
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Stack>
    </Box>
  );
}

import { red } from "@mui/material/colors";

export function MuiButton(theme){
  return {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          height: '39px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundImage: "radial-gradient(circle at 20% -20%, rgba(255,209,18,0) 0%, rgba(255,44,16,0) 100%)",
          transition: '1s',
          '&:hover':{
            backgroundImage: "radial-gradient(circle at 20% -20%, rgba(255,209,18,1) 0%, rgba(255,44,16,1) 100%)",
            color: theme.palette.text.primary,

          },
        }
      }
  }
};
}

export const typography= {
    fontFamily: [
        'Manrope',
        'sans-serif',
      ].join(','),
      button: {
        textTransform: 'none'
      }
    };
    

    /*

typography: Object

    htmlFontSize: 16
    pxToRem: f ()
    fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
    fontSize: 14
    fontWeightLight: 300
    fontWeightRegular: 400
    fontWeightMedium: 500
    fontWeightBold: 700

h1: Object

    fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
    fontWeight: 300
    fontSize: "6rem"
    lineHeight: 1.167
    letterSpacing: "-0.01562em"
    */

import { Alert,Box  } from '@mui/material' ;
export default function SuccesErrorAlert({response}){

    return(
        <Box sx={{height:'50px'}}>
            {response?.error && <Alert severity="error">Error: {response.error.message}</Alert>}
            {response?.succes && <Alert severity="success">Status: {response.succes}</Alert>}
          </Box>
    )
}


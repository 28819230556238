import { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  TextField,
} from '@mui/material';
import { rebootCharger, startCharging, stopCharging } from 'utils/emabler';
import { useForm } from '../../hooks/useForm';
import StartChargingForm from './RebootChargerDialog';

export default function RebootChargerDialog({ open, setOpen, charger, setResponse }) {
  const initialValue = {
    chargerId: charger.chargerId,
    socketId: '',
    rfidTag: '',
  };

  const { value, handleChange, resetForm, error, setError } = useForm(initialValue, true, validate);
  const {
    value: valueStop,
    handleChange: handleChangeStop,
    resetForm: resetFormStop,
    error: errorStop,
    setError: setErrorStop,
  } = useForm(initialValue, true, validateStop);

  const avaiable = 'Available';
  const charging = 'Charging';

  function validate(fieldValues = value) {
    let temp = { ...error };
    if ('rfidTag' in fieldValues) {
      temp.rfidTag = fieldValues.rfidTag !== '' ? '' : 'Please writte a name.';
    }
    if ('socketId' in fieldValues) {
      temp.socketId = fieldValues.socketId !== '' ? '' : 'Please choose socket id.';
    }
    setError({ ...temp });
    if (fieldValues === value) {
      return Object.values(temp).every((x) => x === '');
    }
  }
  function validateStop(fieldValues = valueStop) {
    let temp = { ...errorStop };
    if ('socketId' in fieldValues) {
      temp.socketId = fieldValues.socketId !== '' ? '' : 'Please choose socket id.';
    }
    setErrorStop({ ...temp });
    if (fieldValues === valueStop) {
      return Object.values(temp).every((x) => x === '');
    }
  }
  function handleReboot() {
    rebootCharger(charger.chargerId).then((r) => {
      setResponse(r);
    });
    setOpen(false);
  }
  function handleSubmitStart(e) {
    e.preventDefault();
    if (validate()) {
      startCharging(value.chargerId, value.socketId, value.rfidTag).then((r) => {
        setResponse(r);
      });
      resetForm();
    }
  }
  function handleSubmitStop(e) {
    e.preventDefault();
    if (validateStop()) {
      stopCharging(valueStop.chargerId, valueStop.socketId).then((r) => {
        setResponse(r);
      });
      resetFormStop();
    }
  }
  function handleCancel() {
    resetFormStop();
    resetForm();
    setOpen(false);
  }
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="reboot-charger"
      aria-describedby="reboot-charger-description"
    >
      <DialogTitle variant="h5" sx={{ textAlign: 'center', pb: 0 }}>
        {`Charger Control`}
      </DialogTitle>
      <DialogTitle variant="h6" sx={{ textAlign: 'center', mb: 2, pt: 0 }}>
        {`for ${charger.chargerId}`}
      </DialogTitle>
      <DialogContent sx={{ minWidth: '350px' }}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
          <Box component="form" noValidate autoComplete="on" onSubmit={(e) => handleSubmitStart(e)}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
              <Typography variant="h6">Start Charging</Typography>
              <FormControl error={error.socketId !== '' ? true : false}>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <RadioGroup aria-labelledby="choose-socket-radio-buttons-label" name="socketId">
                    {charger.sockets.map((socket, i) => (
                      <FormControlLabel
                        key={i}
                        value={socket.id}
                        control={<Radio />}
                        label={`Socket ${socket.id} - ${socket.status} `}
                        //disabled={socket.status === avaiable ? false : true}
                        onChange={handleChange}
                      />
                    ))}
                    {error.socketId && <FormHelperText>{error.socketId}</FormHelperText>}
                  </RadioGroup>
                  <TextField
                    label="rfidTag"
                    required
                    variant="outlined"
                    name="rfidTag"
                    value={value.rfidTag}
                    onChange={handleChange}
                    {...(error.rfidTag && {
                      error: true,
                      helperText: error.rfidTag,
                    })}
                  />
                </Stack>
              </FormControl>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button autoFocus variant="contained" type="submit">
                  Start Charging
                </Button>
              </DialogActions>
            </Stack>
          </Box>

          <Box component="form" noValidate autoComplete="on" onSubmit={(e) => handleSubmitStop(e)}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
              <Typography variant="h6">Stop Charging</Typography>
              <FormControl error={errorStop.socketId !== '' ? true : false}>
                <RadioGroup aria-labelledby="choose-socket-radio-buttons-label" name="socketId">
                  {charger.sockets.map((socket, i) => (
                    <FormControlLabel
                      key={i}
                      value={socket.id}
                      control={<Radio />}
                      label={`Socket ${socket.id} - ${socket.status} `}
                      //disabled={socket.status === charging ? false : true}
                      onChange={handleChangeStop}
                    />
                  ))}
                  {errorStop.socketId && <FormHelperText>{errorStop.socketId}</FormHelperText>}
                </RadioGroup>
              </FormControl>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button autoFocus variant="contained" type="submit">
                  Stop Charging
                </Button>
              </DialogActions>
            </Stack>
          </Box>

          <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Typography variant="h6">Reboot Charger</Typography>
            <DialogActions>
              <Button type="submit" variant="outlined" onClick={handleReboot}>
                Reboot Charger
              </Button>
            </DialogActions>
          </Stack>
        </Stack>
        <DialogActions>
          <Button autoFocus variant="outlined" onClick={handleCancel} sx={{ mt: 4 }}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

import AppHeader from './Header.js';
import '../index.css';
import { Box } from '@mui/material';

export default function BaseLayout({ child }) {
  return (
    <Box sx={{ display: 'flex', mt: '140px' }}>
      <AppHeader />
      <Box component="main" sx={{ flexGrow: 1, pl: '16px', mb: '200px', pr: '10%' }}>
        {child}
      </Box>
    </Box>
  );
}

import React, { useContext, useState, useEffect, createContext } from 'react';
import { supabase } from '../supabaseClient';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);

  useEffect(() => {
    try {
      const session = supabase.auth.session();
      setSession(session);
      setLoading(false);
      //setLoading( session ? false: true);
    } catch (error) {
      console.log('error', error);
    }

    const { data: listener } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      setLoading(false);
    });
    // cleanup the useEffect hook
    return () => {
      listener?.unsubscribe();
    };
  }, []);

  useEffect(() => {}, [session]);
  // create signUp, signIn, signOut functions
  const value = {
    signOut: () => supabase.auth.signOut(),
    session,
    loading,
  };

  // use a provider to pass down the value
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// export the useAuth hook
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) throw Error('useAuth must be used within AuthProvider');
  return context;
};


import { Typography } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { getProfile } from 'utils/apis';
import {useAuth} from "../hooks/useAuth";
import {AuthContext} from "../hooks/useAuth";
import {useProfile} from "../hooks/useProfile";

const AccountProfile = () => {
  const [allowedSystems, setAllowedSystems] =useState([]);

  const authContext = useContext(AuthContext);
  const {profile}= useProfile(authContext.session.user.id);

  useEffect(()=>{
    if(profile){
        setAllowedSystems(profile.SystemIds);
    }
},[profile]);

  return (
    <>
    <Typography variant="body1" component="p" >Hallo: {authContext.session.user.email}</Typography>
    <Typography variant="body1" component="p" >The systems you have access to:</Typography>
      <ul>
        {allowedSystems.map((system,i)=><Typography variant="body1" component="li" key={i}>{(system==="*")?'All Systems' : system}</Typography>)}
      </ul>
      
    </>
  )
}
export default AccountProfile;

import react from 'react';

import { GridToolbar,GridToolbarContainer} from '@mui/x-data-grid';
import { Typography, Stack } from '@mui/material' ;
import { format } from 'date-fns';

function formatTime(date){
    return `${format(new Date(date),'d MMM yy')}`
}

export function CustomTableToolbar({text,date}){
    const {startDate,endDate}=date.selectedDate;
        return (
          <GridToolbarContainer>
            <Stack >
            <Stack
                direction="row"
                spacing={4}
               
            >
                <Typography variant="h5" component="p"sx={{pt:2.5, pb:2, pl:0.8}}>
                    {`${text}: `} 
                </Typography>
                <Typography variant="h6" component="p"sx={{pt:2.5, pb:2, pl:0.8}}>
                    {`${formatTime(startDate)} - ${formatTime(endDate)}`} 
                </Typography> 

            </Stack>
                
            <GridToolbar />
            </Stack>
          </GridToolbarContainer>
        );

}




import { red } from "@mui/material/colors";

export function MuiInput(theme){
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200],
          '&:not(:hover) fieldset':{
            border: `none`,
          },
          '& input':{
            paddingTop: '8px',
            paddingBottom:'8px',
          }
        },
      },
    },
    MuiInputLabel:{
      styleOverrides: {
        root: {
          top: '-8px'
        }}
    } ,
    MuiSelectOutlined:{
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        }}
    } 
  }
};


// TODO rename to loadProfile

import BaseLayout from "../layout/BaseLayout";
import { TransactionDataTable } from "../components/TransactionData/TransactionDataTable";

export default function TransactionDataPage() {
    return (
            <BaseLayout child={<TransactionDataTable/>} />
    )
}





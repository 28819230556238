import { useState,useEffect, useContext } from 'react';
import {AuthContext} from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { supabase } from '../supabaseClient';
import { FormControl, Box, InputLabel, Button, Grid, Stack, Alert, Typography, TextField, ButtonBase, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useAuth} from "../hooks/useAuth";
import { routes } from 'App';

export default function Auth() {
  const initialResponse={user: null,error: null}
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [response, setResponse]=useState(initialResponse);
  const [linkSent, setLinkSent] = useState(false);
  const[location, setLocation]=useState('');
  
  const authContext = useContext(AuthContext);

  const afterSlash = /([^\/]+$)/;
  // if user is loged in redirects to account
  //const {session}= useAuth();
const navigate = useNavigate();

  const theme = useTheme();
  const isSmall= useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(()=>{
    if(authContext.session){
      navigate(routes.account);
    }
  },[authContext])

  useEffect(()=>{
    setLocation(window.location.toString().replace(afterSlash,''));
  },[])
  // error message disapears when input foccused
  const handleFocus =()=>{
    if(response.error){
      setResponse(initialResponse);
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { user, error} = await supabase.auth.signIn({ email }, {
            redirectTo: location
      })

      //console.log('error', error);
      setResponse({user, error});
      if(!error){
        setLinkSent(true);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
        sx={{
          width: '100vw',
          height: '100vh'
      }}
    >
      <Stack 
        direction="column"  
        alignItems="center"
        sx={isSmall?{width:'80%'}:{width:'550px'}}
      >
        <Box
          component="img"
          src={isSmall?`${process.env.PUBLIC_URL}/logo/logo-287x287.png`:`${process.env.PUBLIC_URL}/logo/logo-charge-1026x290.png`} 
          alt="sun2wheel logo" 
          sx={isSmall?{maxWidth:'120px'}:{maxWidth:'550px'}}
        >
        </Box>
        <Stack
        direction="column"
        justifyContent="flex-end"
        alignItems="stretch"
          sx={{
            height:'120px',
            width:'100%',
            pb: '20px'
          }}
        >
          {response.error ? <Alert severity="error" variant="outlined" sx={{borderRadious: '24px'}}>Error: {response.error.message}</Alert>: null}
          {loading ? <Alert severity="info" variant="outlined" sx={{borderRadious: '24px'}}>Sending magic link...</Alert> : null}
        </Stack>
        {linkSent 
          ?(<Typography variant="h4">Please check your email</Typography>)
          :authContext.session
            ? <Link href={routes.systemStatus}>Go to System</Link>
            : (<Stack
            direction="column"
            spacing={3.5}
            sx={{width:'100%',}}
            >
              <Typography variant="h4" component="span" sx={{fontWeight: 800}}>
                Sign in
                  <Typography variant="h5" component="span">
                  {` to member area`}
                  </Typography>
              </Typography>
              
              <FormControl >
                  <InputLabel htmlFor="email" />
                  <TextField 
                    variant="outlined" 
                    label="email" 
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={handleFocus}
                    
                  />
              </FormControl>
              <Button 
                  variant="contained"
                  disabled={loading} 
                  aria-live="polite" 
                  onClick={handleLogin}
                    >
                        Send me a magic link
                </Button>
            </Stack>)
        }
    </Stack>
    </Stack>
)
}

import { useState } from 'react';

import { Link, useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
//import ListItemText from '@mui/material/ListItemText';
import {
  Box,
  Grid,
  ListItemText,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  Divider,
  ListItemButton,
  Typography,
  ListItemIcon,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';

import AnalyticsIcon from '@mui/icons-material/Analytics';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DockIcon from '@mui/icons-material/Dock';
import MenuIcon from '@mui/icons-material/Menu';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { routes } from 'App';

export default function ClippedDrawer() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMiddle = useMediaQuery(theme.breakpoints.up('sm'));
  const { signOut } = useAuth();

  const location = useLocation();

  const navigate = useNavigate();

  const drawerWidth = 280;

  function toggleDrawer() {
    setDrawer(!drawer);
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAccount = () => {
    setAnchorEl(null);
    navigate(routes.account);
  };
  const handleLogOut = () => {
    signOut();
    setAnchorEl(null);
    navigate(routes.home);
  };

  const menuList = [
    {
      to: routes.systemStatus,
      text: 'System Status',
      icon: <AnalyticsIcon />,
    },
    {
      to: routes.loadProfile,
      text: 'Load Profile',
      icon: <MultilineChartIcon />,
    },
    {
      to: routes.transactionData,
      text: 'Transaction Data',
      icon: <AccountBalanceWalletIcon />,
    },

    {
      to: routes.userMgmt2,
      text: 'User Management',
      icon: <PeopleAltIcon />,
    },
    {
      to: routes.chargerMgmt,
      text: 'Charger Management',
      icon: <DockIcon />,
    },
  ];

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ flexGrow: 1 }}
          >
            {isSmall && (
              <IconButton
                size="large"
                aria-label="hamburger menu"
                aria-controls="menu-drawer"
                color="inherit"
                onClick={toggleDrawer}
              >
                <MenuIcon sx={{ width: '35px', height: '35px' }} />
              </IconButton>
            )}
            <Box sx={{ width: drawerWidth }}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/logo/sun2wheel_Logo-weiss.png`}
                alt="sun2wheel logo"
                sx={
                  isSmall
                    ? { height: '48px', width: 'auto', mt: '10px' }
                    : { height: '64px', width: 'auto', mt: '10px' }
                }
              />
            </Box>

            {isMiddle && (
              <Typography variant="h6" component="p">
                Administrator
              </Typography>
            )}
          </Grid>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle sx={{ width: '35px', height: '35px' }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleAccount}>Account</MenuItem>
            <MenuItem onClick={handleLogOut}>Log out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant={isMiddle && 'permanent'}
        open={drawer}
        sx={{
          width: drawerWidth,
          mt: '140px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar sx={{ mt: '140px' }}>
          <List>
            {menuList.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton href={item.to} component={Link} to={item.to} sx={{ pl: 0, pr: 0 }}>
                  <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={
                      location.pathname === item.to ? { fontWeight: 800 } : { fontWeight: 400 }
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Toolbar>
      </Drawer>
    </>
  );
}



export const  filterAllowedSystems=(chargers, allowedSystems)=>{
    if (allowedSystems[0]==="*"){
        return chargers;
    } else {
        return chargers.filter((charger)=> allowedSystems.includes(charger.groupName))
    }
} 
export const  filterOneSystem=(chargers, groupName)=>{
    if (groupName===""){
        return chargers;
    } else {
        return chargers.filter((charger)=> charger.groupName===groupName);
    }
} 

export function sortArrayByObjectValue(array, key){
    const sorted= [...array];
    return sorted.sort((a,b)=>Number(a[key])-Number(b[key]));
}
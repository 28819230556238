import {
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
} from '@mui/material';
import { addUser, editUser, authoriseUserToGroups } from 'utils/emabler';
import SelectSystemIdForForm from '../Reusable/SelectSystemIdForForm';
import { useForm } from '../../hooks/useForm';

export default function AddEditUserDialog({
  open,
  setOpen,
  user = { name: '', homeGroupId: '' },
  allowedSystems,
  setResponse,
}) {
  const initialValue = {
    name: user.name, // string
    systemId: user.homeGroupId,
  };

  const { value, handleChange, resetForm, error, setError } = useForm(initialValue, true, validate);

  function handleCancel() {
    resetForm();
    setOpen(false);
  }

  function validate(fieldValues = value) {
    let temp = { ...error };
    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : 'Please writte users name.';
    }
    if ('systemId' in fieldValues) {
      temp.systemId = fieldValues.systemId !== '' ? '' : 'Please choose a system id.';
    }

    setError({ ...temp });
    // call only on submit; called without props
    if (fieldValues === value) {
      // must be an empty string to validate
      return Object.values(temp).every((x) => x === '');
    }
  }

  function handleAddUser(e) {
    e.preventDefault();
    if (validate()) {
      if (user.id) {
        editUser(user.id, value.name, value.systemId).then((r) => {
          setResponse(r);
        });
      } else {
        addUser(value.name, value.systemId).then((r) => {
          setResponse(r);
          authoriseUserToGroups(r.data.id, [value.systemId]).then((r) => setResponse(r));
        });
      }
      resetForm();
      setOpen(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby={user.id ? 'edit-user' : 'add-user-to-system'}
      aria-describedby={user.id ? 'edit-user' : 'add-user'}
    >
      <DialogTitle>{user.id ? 'Edit User' : 'Add User'}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="on" onSubmit={(e) => handleAddUser(e)}>
          <FormControl component={Stack} spacing={2}>
            <TextField
              label="name"
              required
              variant="outlined"
              name="name"
              value={value.name}
              onChange={handleChange}
              {...(error.name && {
                error: true,
                helperText: error.name,
              })}
            />
            {allowedSystems && (
              <SelectSystemIdForForm
                allowedSystems={allowedSystems}
                name="systemId"
                value={value.systemId}
                handleChange={handleChange}
                error={error.systemId}
              />
            )}
            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button autoFocus type="submit" variant="contained">
                {user.id ? 'Edit User' : 'Add User'}
              </Button>
            </DialogActions>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
//4293FAABA5080

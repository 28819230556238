import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import DeleteTagDialog from './DeleteTagDialog';

import ClearIcon from '@mui/icons-material/Clear';

export default function TagCard({ tag, userId, setResponse }) {
  const [open, setOpen] = useState(false);

  //console.log('user', user);
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRow: '1fr',
        gridAutoFlow: 'row',
        width: '100%',
        mb: '10px',
      }}
    >
      <Typography variant="body1">Tag Id:{tag.idTag}</Typography>
      <Typography variant="body1">Expires: {tag.expiringDate}</Typography>
      <Typography variant="body1">Starts: {tag.startDate}</Typography>
      <Button
        variant="text"
        startIcon={<ClearIcon />}
        sx={{ gridColumn: '2', gridRow: '1/4', justifySelf: 'end' }}
        onClick={() => setOpen(true)}
      />
      <DeleteTagDialog
        userId={userId}
        tagId={tag.idTag}
        open={open}
        setOpen={setOpen}
        setResponse={setResponse}
      />
    </Box>
  );
}

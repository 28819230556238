import { FormControl, InputLabel,Select,MenuItem } from '@mui/material' ;
// returns Group ID!
export default function SelectSystemId({allowedSystems,sysIdSelected,setSysIdSelected}){
    return (
        <FormControl sx={{ m: 0 }} >
                  <InputLabel id="System-id-select-label" >
                      SystemId
                  </InputLabel>
                  <Select
                  variant="outlined"
                  labelId="System-id-select-label"
                  value={sysIdSelected? sysIdSelected: ''}
                  label="System Id"
                  onChange={(e)=>setSysIdSelected(e.target.value)}
                  sx={{height:'39px',
                          maxWidth:'300px'
                  }}
                  >
                      {allowedSystems.map((v, ix)=> {
                              return <MenuItem value={v.id} key={ix}> {v.groupName} </MenuItem>
                          })
                      }
                  </Select>

              </FormControl>
    )
}

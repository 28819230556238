import { supabase,} from '../supabaseClient';

export async function signIn(email){
    const { user, session, error} = await supabase.auth.signIn({ email })
    return { user, session, error} 
};

export async function getSession(){
    const session = await supabase.auth.session()
    return session
}

export async function getAdminConfirmation(id) {
    const {data, error} = await supabase
    .from("profiles")
        .select("isAdmin")
        .eq("id", id)
    return data[0].isAdmin;
} ;

export async function getProfile(id) {
    const {data, error} = await supabase
    .from("profiles")
        .select("*")
        .eq("id", id)
    return {data: data[0], error}
} ;

export async function getUserSystemIds(id) {
    const {data, error} = await supabase.from("profiles")
        .select("SystemIds")
        .eq("id", id)
    return {data:data[0].SystemIds,error}
}

export async function addProfile(user) {
    const {data, error} = await supabase
    .from("profiles")
    .insert(user)
    .select()
    return data;
} ;

export async function getAllProfiles() {
    const {data, error}  = await supabase
    .from("profiles")
    .select('*')
    return data;
} ;

import { useState } from 'react';

export function useForm(initialValue, shouldValidate = false, validate) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState({});

  function handleChange(e) {
    setValue({ ...value, [e.target.name]: e.target.value });
    if (shouldValidate) {
      validate({ [e.target.name]: e.target.value });
    }
  }

  function resetForm() {
    setValue(initialValue);
    setError({});
  }
  return {
    value,
    setValue,
    handleChange,
    resetForm,
    error,
    setError,
  };
}

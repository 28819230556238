import {
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
} from '@mui/material';
import { addUser, editUser, addCharger } from 'utils/emabler';
import SelectSystemIdForForm from '../Reusable/SelectSystemIdForForm';
import { useForm } from '../../hooks/useForm';

export default function AddEditChargerDialog({
  open,
  setOpen,
  charger = { chargerId: '', groupId: '' },
  allowedSystems,
  setResponse,
}) {
  const initialValue = {
    chargerId: charger.chargerId,
    //chargerId: charger.id
    //??groupId: charger.homeGroupId,
  };
  /*
  const { value, handleChange, resetForm, error, setError } = useForm(initialValue, true, validate);

  function handleCancel() {
    resetForm();
    setOpen(false);
  }

  function validate(fieldValues = value) {
    let temp = { ...error };
    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : 'Please writte users name.';
    }
    if ('systemId' in fieldValues) {
      temp.systemId = fieldValues.systemId !== '' ? '' : 'Please choose a system id.';
    }

    setError({ ...temp });
    // call only on submit; called without props
    if (fieldValues === value) {
      // must be an empty string to validate
      return Object.values(temp).every((x) => x === '');
    }
  }
*/
  function handleAddCharger(e) {
    e.preventDefault();

    //console.log('add');
    addCharger('chargerId', 'groupId').then((r) => {
      setResponse(r);
    });

    //resetForm();
    setOpen(false);
  }
  function handleCancel() {
    //resetForm();
    setOpen(false);
  }
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      //aria-labelledby={user.id ? 'edit-user' : 'add-user-to-system'}
      //aria-describedby={user.id ? 'edit-user' : 'add-user'}
    >
      <DialogTitle>{false ? 'Edit Charger' : 'Add Charger'}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="on" onSubmit={(e) => handleAddCharger(e)}>
          <FormControl component={Stack} spacing={2}>
            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button autoFocus type="submit" variant="contained">
                {false ? 'Edit Charger' : 'Add Charger'}
              </Button>
            </DialogActions>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

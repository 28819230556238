import { Typography, Stack, Grid } from '@mui/material';
import UserCard from './UserCard';

export default function UsersList({ userGroup, allowedSystems, setResponse }) {
  if (userGroup.id) {
    return (
      <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        <Typography variant="h6">Group Name: {userGroup.groupName}</Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={3}
        >
          {userGroup.users.map((user) => (
            <UserCard
              user={user}
              key={user.id}
              allowedSystems={allowedSystems}
              setResponse={setResponse}
            />
          ))}
        </Grid>
      </Stack>
    );
  } else {
    return (
      <Typography variant="h6" component="p">
        Error {userGroup.groupId}
      </Typography>
    );
  }
}

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { deleteUser } from 'utils/emabler';

export default function DeleteUserDialog({ open, setOpen, user, setResponse }) {
  function handleDelete() {
    deleteUser(user.id).then((r) => setResponse(r));
    setOpen(false);
  }
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="delete-user-from-system"
      aria-describedby="delete-user-description"
    >
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent>
        <Typography variont="body1">Are you sure that you want to delete: {user.name}</Typography>
        <DialogActions>
          <Button type="submit" onClick={handleDelete}>
            Delete User
          </Button>
          <Button autoFocus variant="contained" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

// TODO this should be a envaromental var
const APIKEY_EMABLER =
  '4yObdLxQUKQgYBNnlH2j5UpuJn4v6iJp4gihi8l3DfdUQrloBJJ2BMQB6Tka35QkJ7af6Omi9zFdQN4G9KyZjoZEg2pvXQxYOmvF';
const CLIENTID_EMABLER = 'SUN2WHEEL';
const BASEURL_EMABLER = 'https://api.emabler.io/api';

const getChargersByGroup = () => {
  let endPoint = `${BASEURL_EMABLER}/groups?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
  return endPoint;
};

const getUsersByGroup = (groupId) => {
  let endPoint = `${BASEURL_EMABLER}/authorise/users/group/${groupId}/?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
  return endPoint;
};

const getUsers = () => {
  let endPoint = `${BASEURL_EMABLER}/authorise/users?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
  return endPoint;
};

// /authorise/user/{userid}
const getUser = (userid) => {
  let endPoint = `${BASEURL_EMABLER}/authorise/user/${userid}?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
  return endPoint;
};

// /chargers Return an array of all charger
const getAllChargers = () => {
  return `${BASEURL_EMABLER}/chargers?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

// /authorise/user/add
const authoriseUserEndpoint = () => {
  return `${BASEURL_EMABLER}/authorise/user/add?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

// ​/authorise​/user​/{userid}​/update Update authorised user PUT
const updateUserEndpoint = (userId) => {
  return `${BASEURL_EMABLER}/authorise/user/${userId}/update?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

// /authorise/user/{userid} DELETE
const deleteUserEndpoint = (userId) => {
  return `${BASEURL_EMABLER}/authorise/user/${userId}?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

// /authorise/user/{userid}/groups
const authoriseUserToGroupsEndpoint = (userId) => {
  return `${BASEURL_EMABLER}/authorise/user/${userId}/groups?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

// /authorise/user/{userid}/add/tag
const addTagEndpoint = (userId) => {
  return `${BASEURL_EMABLER}/authorise/user/${userId}/add/tag?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

///authorise/user/{userid}/tag/{idTag}
const deleteTagEndpoint = (userId, tagId) => {
  return `${BASEURL_EMABLER}/authorise/user/${userId}/tag/${tagId}?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};
///charger/startCharging/{chargerId}/{socketId}/{rfidTag}
const startChargerEndpoint = (chargerId, socketId, rfidTag) => {
  return `${BASEURL_EMABLER}/charger/startCharging/${chargerId}/${socketId}/${rfidTag}?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

///charger/stopCharging/{chargerId}/{socketId}
const stopChargerEndpoint = (chargerId, socketId) => {
  return `${BASEURL_EMABLER}/charger/stopCharging/${chargerId}/${socketId}?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

// /charger/reboot/{chargerId}/{type} GET
const rebootChargerEndpoint = (chargerId) => {
  return `${BASEURL_EMABLER}/charger/reboot/${chargerId}/full?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};
///charger/add/{chargerId}/{groupId}
const addChargerEndpoint = (chargerId, groupId) => {
  return `${BASEURL_EMABLER}/charger/add/${chargerId}/${groupId}?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
};

//curl -X GET "https://api.emabler.net/api/authorise/users" -H  "accept: application/json"
export const getChargerTxEndpoint = () => {
  let endPoint = `${BASEURL_EMABLER}/statistics/transactions?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`;
  //let endPoint = `${BASEURL_EMABLER}/statistics/transactions?code=${APIKEY_EMABLER}&clientId=${CLIENTID_EMABLER}`
  return endPoint;
};

export const fetchUsersByGroupPromise = (groupId) => {
  //console.log('groupId',groupId)
  return fetch(getUsersByGroup(groupId))
    .then((r) => r.json())
    .then((r) => {
      return r;
    })
    .catch((e) => {
      return { e, groupId };
    });
};

export const fetchUsersByGroup = (groupId, setter) => {
  fetch(getUsersByGroup(groupId))
    .then((r) => r.json())
    .then((r) => setter(() => [r]))
    .catch((e) => console.log('e', e));
};

export const fetchChargers = (setter) => {
  fetch(getChargersByGroup())
    .then((r) => r.json())
    .then((r) => setter(r));
};

export const fetchAllChargers = (setter) => {
  fetch(getAllChargers())
    .then((r) => r.json())
    .then((r) => setter(r));
};

const findSystems = (country) => (fleet) => (data) => {
  return data
    .reduce((acc, v) => (v.groupName === country ? v.subgroups : acc), {})
    .reduce((acc, v) => (v.groupName === fleet ? v.subgroups : acc), {});
};

export const fetchAllSystems = (setter) => {
  fetch(getChargersByGroup())
    .then((r) => r.json())
    .then((r) => findSystems('CH')('sun2wheel')(r))
    .then((r) => {
      return r;
    })
    .then((r) => setter(r.map((v) => v)));
};

export const fetchUsers = (setter) => {
  fetch(getUsers())
    .then((r) => r.json())
    .then((r) => setter(r));
};

export const fetchUser = async (userId) => {
  try {
    let response = await fetch(getUser(userId));
    return await response.json();
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchTxData = (chargerIds, tStart, tEnd) => {
  let query = {
    startTime: new Date(tStart),
    // "startTimeSearchEnd": new Date(tEnd),
    // "stopTimeSearchBegin": new Date(tStart),
    stopTime: new Date(tEnd),
    chargerIDs: chargerIds,
  };
  return fetch(getChargerTxEndpoint(), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  })
    .then((r) => r.json())
    .then((r) => {
      return JSON.parse(r);
    });
};

export const addUser = async (name, homeGroup) => {
  let query = {
    name: name,
    homeGroup: homeGroup,
  };
  try {
    let response = await fetch(authoriseUserEndpoint(), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });
    let data = await response.json();
    if (!response.ok) {
      throw Error(response.statusText + ` User with name ${name} already exist. Try another name.`);
    }
    return await { data: JSON.parse(data), succes: response.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};

export const editUser = async (userId, name, homeGroup) => {
  let query = {
    name: name,
    homeGroup: homeGroup,
  };
  try {
    let r = await fetch(updateUserEndpoint(userId), {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });
    if (!r.ok) {
      throw Error(r.statusText + ` User with name ${name} already exist. Try another name.`);
    }
    return await { data: {}, succes: r.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};

export const deleteUser = async (userId) => {
  try {
    let r = await fetch(deleteUserEndpoint(userId), {
      method: 'DELETE',
    });
    if (!r.ok) {
      throw Error(r.statusText + ': Failed');
    }
    return await { data: {}, succes: r.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};

export const authoriseUserToGroups = async (userId, groupIds) => {
  let query = {
    groupIds, //[]
  };
  try {
    let r = await fetch(authoriseUserToGroupsEndpoint(userId), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });
    if (!r.ok) {
      throw Error(r.statusText);
    }
    return await {
      data: {},
      succes: r.statusText + ` User authorised to ${groupIds[0]} group.`,
      error: null,
    };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};

export const addTag = async (userId, idTag, expiringDate, startDate) => {
  let query = {
    idTag,
    expiringDate: new Date(expiringDate),
    startDate: new Date(startDate),
  };
  try {
    let response = await fetch(addTagEndpoint(userId), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });
    if (!response.ok) {
      throw Error(response.statusText + ` The tag ${idTag} already exist.`);
    }
    return await { data: {}, succes: response.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};

export const deleteTag = async (userId, tagId) => {
  try {
    let response = await fetch(deleteTagEndpoint(userId, tagId), {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return await { data: {}, succes: response.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};
export const startCharging = async (chargerId, socketId, rfidTag) => {
  try {
    let r = await fetch(startChargerEndpoint(chargerId, socketId, rfidTag));
    if (!r.ok) {
      throw Error(r.statusText + ': Failed');
    }
    return await { data: {}, succes: r.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};
export const stopCharging = async (chargerId, socketId) => {
  try {
    let r = await fetch(stopChargerEndpoint(chargerId, socketId));
    if (!r.ok) {
      throw Error(r.statusText + ': Failed');
    }
    return await { data: {}, succes: r.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};
export const rebootCharger = async (chargerId) => {
  try {
    let r = await fetch(rebootChargerEndpoint(chargerId));
    if (!r.ok) {
      throw Error(r.statusText + ': Failed');
    }
    return await { data: {}, succes: r.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};
export const addCharger = async (chargerId, groupId) => {
  /*
  let query = {
    idTag,
    expiringDate: new Date(expiringDate),
    startDate: new Date(startDate),
  };
*/
  try {
    let response = await fetch(addChargerEndpoint(chargerId, groupId), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify(query),
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return await { data: {}, succes: response.statusText, error: null };
  } catch (e) {
    return { data: null, succes: null, error: e };
  }
};

import {
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import { addTag } from 'utils/emabler';
import SelectSystemIdForForm from '../Reusable/SelectSystemIdForForm';
import { useForm } from '../../hooks/useForm';

export default function AddTagDialog({ open, setOpen, user, setResponse }) {
  const initialValue = {
    userId: user.id,
    idTag: '',
    expiringDate: '',
    startDate: '',
  };

  const { value, handleChange, resetForm, error, setError } = useForm(initialValue, true, validate);

  function handleCancel() {
    resetForm();
    setOpen(false);
  }

  function validate(fieldValues = value) {
    let temp = { ...error };
    if ('idTag' in fieldValues) {
      temp.idTag = fieldValues.idTag ? '' : 'Please writte 8 signs idTag.';
    }
    if ('expiringDate' in fieldValues) {
      temp.expiringDate = fieldValues.expiringDate !== '' ? '' : 'Please choose an expiring date.';
    }
    if ('startDate' in fieldValues) {
      temp.startDate = fieldValues.startDate !== '' ? '' : 'Please choose a start date.';
    }
    setError({ ...temp });
    if (fieldValues === value) {
      return Object.values(temp).every((x) => x === '');
    }
  }

  function handleAddTag(e) {
    e.preventDefault();
    if (validate()) {
      addTag(user.id, value.idTag, value.expiringDate, value.startDate).then((r) => {
        setResponse(r);
      });
      resetForm();
      setOpen(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby={user.id ? 'edit-tag' : 'add-tag-to-system'}
      aria-describedby={user.id ? 'edit-tag' : 'add-tag'}
    >
      <DialogTitle>Add Tag</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="on" onSubmit={(e) => handleAddTag(e)}>
          <FormControl component={Stack} spacing={3}>
            <TextField
              label="idTag"
              required
              variant="outlined"
              name="idTag"
              value={value.idTag}
              onChange={handleChange}
              {...(error.idTag && {
                error: true,
                helperText: error.idTag,
              })}
            />
            <FormControl>
              <Typography variant="body2">Tag Start</Typography>
              <Input
                type="date"
                variant="outlined"
                name="startDate"
                value={value.startDate}
                onChange={handleChange}
                error={error.startDate ? true : false}
              />
            </FormControl>
            <FormControl>
              <Typography variant="body2">Tag Expiry</Typography>
              <Input
                type="date"
                variant="outlined"
                name="expiringDate"
                value={value.expiringDate}
                onChange={handleChange}
                error={error.expiringDate ? true : false}
              />
            </FormControl>
            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button autoFocus type="submit" variant="contained">
                {'Add Tag'}
              </Button>
            </DialogActions>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import { Box, Stack, TextField } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import {AuthContext} from "../hooks/useAuth";
import {InfluxDB} from '@influxdata/influxdb-client';
import Plot from 'react-plotly.js';
import TxDateRangePickerKK from "./Reusable/DateRangePickerKK";

import SystemIdSelectorKK from "./Reusable/SystemIdSelectorKK"
import {Grid, Typography} from '@mui/material';

import {useProfile} from "../hooks/useProfile";

import { Container } from '@mui/system';

let fieldVec = 	[
    "lLimitPotential",	
    "uLimitRequest",
    "lLimitRequest",
    "uLimitSetpoint",
    "presentConsumption",
]

const _DCMSColors = ["green","lightskyblue", "blue", "red", "orange"]
let DCMSColors = fieldVec.reduce((acc, v, ix) => {
    acc[v]=_DCMSColors[ix]
    return acc
}, {})


const token = 'gJ7eSwPp5t2Tyt1mxxOJNQe6t5-eOwvtP_98W1-73DWXkXIzAJwKn_jfsI6LUv3KWimuYevFl-2IN1TQiyDAgQ=='
const org = 'sandro.schopfer@sun2wheel.ch'
const bucket = 'ComponentStats'
const client = new InfluxDB({url: 'https://eu-central-1-1.aws.cloud2.influxdata.com', token: token})
const queryApi = client.getQueryApi(org)

function toUNIX(d) {
    return Math.floor(d.getTime() / 1000.0)
}

const unique = (value, index, self) => {
    return self.indexOf(value) === index
}

const sortIx = (x) => {
    let xSorted = [...x].sort(); // avoid inplace operation
    let ixs = xSorted.map(v => x.indexOf(v))
    return ixs
}

const flatten =  (acc, val) => acc.concat(val);


function aggregateTraces(_traces) {
    let traces = [..._traces]
    console.log("length traces", traces.length )
    let traceSum = 	{
                        x: traces[0].x,
                        y: traces[0].y.map(x=> x * 0),
                        line: {shape: 'hv'},
                        name: "Tot consumption",
                        type: "scatter",
                        fill: 'tozeroy',
                        mode: 'none', // avoid boundary lines
                    }
    

    let __tVec = []
    for (let trace of traces) {
        __tVec.push(trace.x)
    }

    let tUniques = __tVec
                    .reduce(flatten, [])
                    .filter((date, i, self) => 
                          self.findIndex(d => d.getTime() === date.getTime()) === i
                    )
                    .sort((a, b) => {
                        return a.getTime() - b.getTime()
                    })
    

    traceSum.x = tUniques

    for (let trace of traces) {
        let traceTime = trace.x.map(t => t.getTime())
        for (let tIx in traceSum.x ) {
            let tVal = traceSum.x[tIx] 
            let ixOfCurrentTrace = traceTime.indexOf(tVal.getTime())
            
            let toAdd = 0
            if (ixOfCurrentTrace >= 0) {
                toAdd = trace.y[ixOfCurrentTrace]
            }

            traceSum.y[tIx] += toAdd
        }
        

        //traceSum.y = trace.y.map((v, ix) => traceSum.y[ix] + v)
    }
   
    return traceSum
}

function queryStringPowerRaw(sysId, tStart, tEnd) {
    const query = `from(bucket: "DCMSMessages")
    |> range(start: ${tStart}, stop: ${tEnd})	
    |> filter(fn: (r) => r["_measurement"] == "MeasurementPower")
    |> filter(fn: (r) => r["_field"] == "presentConsumption")
    |> filter(fn: (r) => r["SystemId"] == "${sysId}")
    |> sort(columns: ["_time"], desc: false)`
    return query
}

function queryStringPower1min(sysId, tStart, tEnd) {
    const query = `from(bucket: "ComponentStats_1min")
    |> range(start: ${tStart}, stop: ${tEnd})	
    |> filter(fn: (r) => r["_measurement"] == "Power")
    |> filter(fn: (r) => r["_field"] == "presentConsumption")
    |> filter(fn: (r) => r["SystemId"] == "${sysId}")
    |> sort(columns: ["_time"], desc: false)`
    return query
}

function queryStringAllDCMSFields(sysId, tStart, tEnd) {
    const query = `from(bucket: "DCMSMessages")
    |> range(start: ${tStart}, stop: ${tEnd})
    |> filter(fn: (r) => r["_measurement"] == "MeasurementPower")
    |> filter(fn: (r) => r["_field"] == "presentConsumption" or 
                            r["_field"] == "uLimitSetpoint" or 
                            r["_field"] == "lLimitPotential" or 
                            r["_field"] == "uLimitRequest" or 
                            r["_field"] == "lLimitRequest"
                            )
    |> filter(fn: (r) => r["SystemId"] == "${sysId}")
    |> sort(columns: ["_time"], desc: false)`
    return query
}

 


export function TSPlot() {
    const [allowedSystems, setAllowedSystems] =useState(null);
    const [SysQueryRes, setSysQueryRes]= useState([]);
    const [sysIdSelected, setSysIdSelected] = useState('');
    //console.log('sysIdSelected',sysIdSelected);
    const now =Date.now();
    const initialState ={
        startDate: new Date(now-(1000*60*60*24)),
        endDate: new Date(now) ,
        key: 'selection'
    }

    const [selectedDate, setSelectedDate] = useState(initialState);
    const authContext = useContext(AuthContext);
 
    const {profile}= useProfile(authContext.session.user.id);

    useEffect(()=>{
        if(profile){
            setAllowedSystems(profile.SystemIds);
            if(profile.SystemIds.length===1 && profile.SystemIds[0]!=="*"){
                setSysIdSelected(profile.SystemIds[0])
            }
        }
    },[profile]);

    //const _getDateSelected = getDateSelected(chargers, setTableData);
    //console.log('selectedDate',selectedDate);
    let tempRes = "1min"
    let queryPower = tempRes == "1min" ? queryStringPower1min : queryStringPowerRaw

    async function Query(sysId, tStart, tEnd, queryFunc) {

        const query = queryFunc(sysId, tStart, tEnd)
        //console.log("query = ", query)

        queryApi
                .collectRows(query)
                .then(data => {
                    let QueriedData = {}
                    data.forEach(x => JSON.stringify(x))
                    let consIds = data.map(x => x.ConsId).filter(unique)            
                    data._time = data.map(x => new Date(x._time))
                    for (let fieldVal of fieldVec) {
                        let traces = []
                        for (let id of consIds) {
                            const filterByConsId = (record) => {
                                return record.ConsId === id
                            }
                            const filterByField = (record) => {
                                return 	record._field === fieldVal
                            }
                            traces.push(
                                {
                                    x: data
                                        .filter(filterByConsId)
                                        .filter(filterByField)
                                        .map(x=> new Date(x._time)),
                                    y: data
                                        .filter(filterByConsId)
                                        .filter(filterByField)
                                        .map(x=> x._value),
                                    //legendgroup: fieldVal, //"group" + (fieldVal != "presentConsumption" ? "2" : "") ,
                                    //legendgrouptitle_text: fieldVal,
                                    line: {shape: 'hv'},
                                    name: id,
                                    type: "scatter",
                                    visible:  (fieldVal === "presentConsumption") ? 'legend' : 'legendonly',
                                }
                            )
                        }
                            QueriedData[fieldVal] = traces
                        }
                    setSysQueryRes(QueriedData)
                })
                .catch(error => {
                    console.error(error);
                    console.log('\nCollect ROWS ERROR');
                })
    }

    useEffect( () => {
        Query(sysIdSelected, toUNIX(selectedDate.startDate), toUNIX(selectedDate.endDate), queryPower);
    }, [sysIdSelected,  selectedDate]);

    return (
        <Box>
            <Stack
                direction="column"
                justifyContent="space-between"
                spacing={2}
            >
                        <Typography 
                            variant="h4" 
                            component="h1" 
                        >
                            Load Profile Explorer
                        </Typography>
                    {allowedSystems 
                        ?<SystemIdSelectorKK 
                            sysIdSelected={sysIdSelected} 
                            setSysIdSelected={setSysIdSelected} 
                            allowedSystems={allowedSystems} 
                            />
                        : null
                    }
                    <TxDateRangePickerKK 
                        setSelectedDate={setSelectedDate} 
                        selectedDate={selectedDate}
                    />
                    <Box sx={{mt: '-20px'}}>
                        <Plot
                            data={SysQueryRes.presentConsumption}
                            layout={{ width: 1000, height: 500 }}
                        />
                    </Box>
            </Stack>
        </Box>
    )
}

import { DataGrid,} from '@mui/x-data-grid';
import {CustomTableToolbar} from "../Reusable/CustomTableToolbar";

export function TableTotal({rows,columns, selectedDate, toolbarText}){

    return (
        <DataGrid 
            autoHeight
            rows={Object.values(rows)}
            columns={columns}
            pageSize={30}
            rowsPerPageOptions={[20]}
            rowGroupingColumnMode="single"
            density="compact"
            components={{
                Toolbar: CustomTableToolbar,
            }}
            componentsProps={{
                toolbar: { 
                    text: toolbarText,
                    date: {selectedDate} 
                }
            }}
        />  
    )
}

import { format } from 'date-fns';
function formatTime(params){
    return `${format(new Date(params.value),'dd MMM yyyy -  k:mm')}`
}

const systemId={field: 'groupName', headerName: 'Group Name', width: 180, };
 const userColumn={field: 'userName', headerName: 'User Name', width: 120, };
 //const userColumn={field: 'userId', headerName: 'UserId', width: 120, };
 const chargerColumn={field: 'chargerId', headerName: 'ChargerId', width: 120  };
const txStartColumn={
    field: 'startTimeUTC',
    headerName: 'Tx Start',
    sortable: false,
    type: 'date',
    width: 150,
    valueFormatter: formatTime,
   
  };
 const txEndColumn={
    field: 'endTimeUTC',
    headerName: 'Tx End',
    description: 'When Session started',
    sortable: false,
    type: 'date',
    width: 150,
    valueFormatter: formatTime,

  }
 const energeConsumedColumn={
    field: 'consumptionKWH',
    headerName: 'Energy consumed (kWh)',
    description: 'Energy (kWh) consumed in session',
    sortable: true,
    type: 'number',
    width: 180,
    hide: false,
    sortable: false,
  }
export const columnsChargerDetail = [
  systemId,
    chargerColumn,
    {...userColumn, sortable:false},
    txStartColumn,
    txEndColumn,
    energeConsumedColumn,
    {
      field: 'sum',
      headerName: 'Total per charger (kWh)',
      description: 'Energy (kWh) consumed per charger in session',
      sortable: true,
      type: 'number',
      width: 180,
      hide: false,
    }
  ];
  //rfidTag
  export const columnsUserDetail=[
    systemId,
    userColumn,
    {...chargerColumn, sortable:false},
    {field: 'rfidTag', headerName: 'rfidTag', width: 150, },
    txStartColumn,
    txEndColumn,
    energeConsumedColumn,
    {
        field: 'sum',
        headerName: 'Total per user (kWh)',
        description: 'Energy (kWh) consumed per user in session',
        sortable: true,
        type: 'number',
        width: 180,
        hide: false, 
      }
  ]

import { createTheme } from '@mui/material/styles';
import {palette} from './palette';
import {typography} from './typography';
import {MuiTextField,MuiInputBase} from './componentsOverrides/MuiInput'

import ComponentsOverrides from './componentsOverrides/index';

export const theme = createTheme({
    palette,
    typography,
    shape: {
      borderRadius:20, 
    },
  });

  theme.components = ComponentsOverrides(theme);